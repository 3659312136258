import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { RisingStars} from 'src/assets';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RegistrationPage() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear any existing errors when user types
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Basic validation
            const validationErrors = validateForm(formData);
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                throw new Error('Form validation failed');
            }

            const response = await Axios.post('http://geniuslogix.com:3500/api/auth/register', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Response:', response.data);
            Swal.fire({
                icon: 'success',
                title: 'User Registered Successfully!',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                navigate('/login');
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message || 'Something went wrong!',
            });
        }
    };

    const validateForm = ({ first_name, last_name, email, password }) => {
        const errors = {};
        if (!first_name.trim()) {
            errors.first_name = 'First Name is required';
        }
        if (!last_name.trim()) {
            errors.last_name = 'Last Name is required';
        }
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(email)) {
            errors.email = 'Invalid email address';
        }
        if (!password.trim()) {
            errors.password = 'Password is required';
        }
        return errors;
    };

    const isValidEmail = (email) => {
        // Simple email format validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div>
            <section className='contactUs-Sec paddingY-100'>
                <div className="ah-container">
                    <div className="row justify-content-center bg-light-gray padding-70-100">
                        <div className="col-md-12 bg-color-box">
                            <div className="description text-center">
                                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Create new user</span></div>
                                <br />
                                <h2>Registration Form<span></span></h2>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label htmlFor="first_name">First Name:</label>
                                    <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
                                    {errors.first_name && <p className="error-message ">{errors.first_name}</p>}
                                </div>
                                <div className='form-group'>
                                    <label htmlFor="last_name">Last Name:</label>
                                    <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
                                    {errors.last_name && <p className="error-message ">{errors.last_name}</p>}
                                </div>
                                <div className='form-group'>
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                                    {errors.email && <p className="error-message ">{errors.email}</p>}
                                </div>
                                <div className='form-group'>
                                    <label htmlFor="password">Password:</label>
                                    <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
                                    {errors.password && <p className="error-message ">{errors.password}</p>}
                                </div>
                                <div className='form-group mt-4 mb-0'>
                                    <button type='submit' className='btn primary desktop animate__fadeInUp animate__animated animate__slow'>Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RegistrationPage;