import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography } from 'src/components/common/Base';
import { RisingStars, DeleteIcon } from 'src/assets';
import './style.scss';

const JobPostList = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const loggedInUserId = localStorage.getItem("loggedInUserId");
        if (!loggedInUserId) {
            navigate('/'); // Redirect to home page if user is logged in
        }
    }, [navigate]);


    const [formData, setFormData] = useState({
        userId: '661f84723b165b6dd5335503',
        title: '',
        thumbnail: '',
        description: '',
        summaryDescription: '',
        experience: '',
        noOfVacancies: '',
        workingHours: '',
        workingDays: '',
        salary: '',
        deadline: '',
        responsibilitiesListItem: '',
        skillsDescriptionList: '',
    });

    const [jobs, setJobs] = useState([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [jobToDelete, setJobToDelete] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            let userId = "661f84723b165b6dd5335503";
            const response = await fetch('http://geniuslogix.com:3500/api/jobs/getAllJobs?userId=' + userId);
            const data = await response.json();

            if (data.length > 0) {
                setJobs(data);
            } else {
                setJobs([]);
            }

        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
        // Clear any existing errors when user types
        setErrors(prevErrors => ({
            ...prevErrors,
            [e.target.id]: ''
        }));
    };

    const handleSkillsDescriptionList = (value) => {
        setFormData({ ...formData, skillsDescriptionList: value });
    };

    const handleResponsibilitiesListItem = (value) => {
        setFormData({ ...formData, responsibilitiesListItem: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationErrors = validateForm(formData);
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                throw new Error('Form validation failed');
            }

            const response = await axios.post('http://geniuslogix.com:3500/api/jobs/postJob', formData);
            console.log('Job added successfully:', response.data);
            fetchJobs();
            setFormData({ // Clear form fields after submission
                userId: '',
                title: '',
                thumbnail: '',
                description: '',
                summaryDescription: '',
                experience: '',
                noOfVacancies: '',
                workingHours: '',
                workingDays: '',
                salary: '',
                deadline: '',
                responsibilitiesListItem: '',
                skillsDescriptionList: '',
            });
            await Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your job has been posted.',
                showConfirmButton: false,
                timer: 1500
            });
            window.location.reload();
        } catch (error) {
            console.error('Error adding job:', error);
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to post the job. Please try again later.',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleDeleteConfirmation = (id) => {
        setJobToDelete(id);
        setDeleteConfirmation(true);
    };

    const handleDelete = async () => {
        try {
            if (jobToDelete) {
                await axios.delete(`http://geniuslogix.com:3500/api/jobs/jobs/${jobToDelete}`);
                console.log('Job deleted successfully');
                fetchJobs();
                setDeleteConfirmation(false);
                console.log('A job has been deleted.');
            } else {
                console.error('No job to delete');
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.error('Job not found:', error.response.data);
            } else {
                console.error('Error deleting job:', error);
            }
        }
    };

    const validateForm = (formData) => {
        const errors = {};
        for (const key in formData) {
            if (!formData[key].trim()) {
                errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        }
        return errors;
    };


    return (
        <>
            {/* <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} /> */}

            <section className="services-sec pb-0">
                <div className="ah-container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="description">
                                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Jobs</span></div>
                                <br />
                                <h2><span>Join Us</span></h2>
                                <p>Genius System Logix provides the comprehensive digital suite of products and services as per clients expectations, summary of range of products is numerated in ensuing headings.</p>
                            </div>
                        </div>
                        <div className='w-100'></div>
                        {jobs && jobs.length > 0 ? (
                            jobs.map(job => (
                                <div key={job.Id} id={job.Id} className='col-md-4 p-relative'>
                                    <Link className="d-link" to={`/career/${job.Id}`}>
                                        <div className='job-box'>
                                            <div className='short-description'>
                                                <h3 className=''>{job.title}</h3>
                                                <div className='info'><span>Remote</span> - <span>Pakistan/Karachi</span> - <span>Full Time</span></div>
                                            </div>
                                            <div className="icon-container">
                                                <span className='icon'>&#10148;</span>
                                            </div>
                                        </div>
                                    </Link>
                                    <button onClick={() => handleDeleteConfirmation(job.Id)} className='remove-icon'>
                                        <img src={DeleteIcon} alt="Remove Icon" />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="col-md-12 text-center">
                                <p className='text-danger'>There are no jobs available right now.</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            {deleteConfirmation && (
                <div className="confirmation-modal">
                    <div>
                        <p>Are you sure you want to delete this job?</p>
                        <button onClick={handleDelete}>Yes</button>
                        <button onClick={() => setDeleteConfirmation(false)}>No</button>
                    </div>
                </div>
            )}

            <section className='contactUs-Sec paddingY-100'>
                <div className="ah-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 bg-color-box bg-light-gray padding-70-100">
                            <div className="description text-center">
                                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Post a new job</span></div>
                                <br />
                                <h2>Job Information<span></span></h2>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> */}
                            </div>
                            <div>
                                {/* {!isSubmitted ? ( */}
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="title">Job Title<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="title"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                />
                                                {errors.title && <p className="error-message">The {errors.title}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="thumbnail">Thumbnail<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="thumbnail"
                                                    value={formData.thumbnail}
                                                    onChange={handleChange}
                                                />
                                                {errors.thumbnail && <p className="error-message">{errors.thumbnail}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="description">Short Description<sup>*</sup>:</label>
                                                <textarea
                                                    id="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                />
                                                {errors.description && <p className="error-message">Short Description is required.</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="summaryDescription">Description<sup>*</sup>:</label>
                                                <textarea
                                                    id="summaryDescription"
                                                    value={formData.summaryDescription}
                                                    onChange={handleChange}
                                                />
                                                {errors.summaryDescription && <p className="error-message">Description is required.</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="experience">Experience<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="experience"
                                                    value={formData.experience}
                                                    onChange={handleChange}
                                                />
                                                {errors.experience && <p className="error-message">{errors.experience}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="noOfVacancies">No of Vacancies<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="noOfVacancies"
                                                    value={formData.noOfVacancies}
                                                    onChange={handleChange}
                                                />
                                                {errors.noOfVacancies && <p className="error-message">No of Vacancies is required. </p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="workingHours">Working Hours<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="workingHours"
                                                    value={formData.workingHours}
                                                    onChange={handleChange}
                                                />
                                                {errors.workingHours && <p className="error-message">Working Hours is required</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="workingDays">Working Days<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="workingDays"
                                                    value={formData.workingDays}
                                                    onChange={handleChange}
                                                />
                                                {errors.workingDays && <p className="error-message">Working days is required.</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="salary">Salary<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="salary"
                                                    value={formData.salary}
                                                    onChange={handleChange}
                                                />
                                                {errors.salary && <p className="error-message">{errors.salary}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group'>
                                                <label htmlFor="deadline">Deadline<sup>*</sup>:</label>
                                                <input
                                                    type="text"
                                                    id="deadline"
                                                    value={formData.deadline}
                                                    onChange={handleChange}
                                                />
                                                {errors.deadline && <p className="error-message">{errors.deadline}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="responsibilitiesListItem">Responsibilities<sup>*</sup>:</label>
                                                <ReactQuill
                                                    id="responsibilitiesListItem"
                                                    value={formData.responsibilitiesListItem} // Value from state
                                                    onChange={handleResponsibilitiesListItem} // Function to handle change
                                                />
                                                {errors.responsibilitiesListItem && <p className="error-message">Responsibilities is required</p>}
                                                {/* {formData.responsibilitiesListItem.map((item, index) => (
                <input
                    key={index}
                    type="text"
                    value={item.listItems}
                    onChange={(e) => handleListItemChange(e, index, 'responsibilitiesListItem')}
                    required
                />
            ))} */}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label htmlFor="skillsDescriptionList">Skills<sup>*</sup>:</label>
                                                <ReactQuill
                                                    id="skillsDescriptionList"
                                                    value={formData.skillsDescriptionList} // Value from state
                                                    onChange={handleSkillsDescriptionList} // Function to handle change
                                                />
                                                {errors.skillsDescriptionList && <p className="error-message">Skills Description is required.</p>}
                                                {/* {formData.skillsDescriptionList.map((item, index) => (
                <input
                    key={index}
                    type="text"
                    value={item.listItems}
                    onChange={(e) => handleListItemChange(e, index, 'skillsDescriptionList')}
                    required
                />
            ))} */}
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4 text-center">
                                            <div className="form-group mb-0 text-center">
                                                <Button className={`btn primary desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
                                                    <Typography variant="button-text">Submit</Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* ) : ( */}
                                {/* <div className='text-center'>
                                        {/* <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
                                        <br />
                                        <h3>Your job has been <span>posted.</span></h3>
                                    </div> */}
                                {/* )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

// HTML Box

export default JobPostList;