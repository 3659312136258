const useAuth = () => {
	const activeUser = false;
	return activeUser;
};

export default useAuth;

// import { useState } from 'react';
// import axios from 'axios';
 
// const useAuth = () => {
// 	const [user, setUser] = useState(null);
// 	const [isLoading, setIsLoading] = useState(false);
	

// 	const login = async (email, password) => {
// 		setIsLoading(true);
// 		try {
// 			const response = await axios.post('http://geniuslogix.com:3500/api/auth/login', {
// 				email,
// 				password,
// 			});
// 			const token = response.data.token;
// 			console.log('This is user token: ', token)
// 			// Assuming your API response contains user information
// 			setUser({ token }); // Set user data in state
// 			setIsLoading(false);
// 			return true; // Return true to indicate successful login
// 		} catch (error) {	
// 			console.error('Login failed:', error.response.data.message);
// 			setIsLoading(false);
// 			return false; // Return false to indicate login failure
// 		}
// 	};

// 	const logout = () => {
// 		setUser(null); // Clear user data from state upon logout
// 	};
// 	return { user, isLoading, login, logout };
// };

// export default useAuth;