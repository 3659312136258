import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button, Typography } from 'src/components/common/Base';
import { RisingStars, Checked } from 'src/assets';

import './style.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedInUserId = localStorage.getItem("loggedInUserId");
        if (loggedInUserId) {
            navigate('/'); // Redirect to home page if user is logged in
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Clear previous errors
        setErrors({});

        // Validate form fields
        const validationErrors = validateForm({ email, password });
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            debugger; // eslint-disable-line no-debugger

            const response = await axios.post('http://geniuslogix.com:3500/api/auth/login', {
                email,
                password,
            });
            localStorage.setItem("loggedInUserId", response.data.obj.userId);
            setIsSubmitted(true);
            Swal.fire({
                icon: 'success',
                title: 'Login Successful!',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                navigate('/post-job');
                window.location.reload();
            });
        } catch (error) {
            console.error('Login failed:', error.response.data.message);
            setErrors({ general: error.response.data.message || 'Login failed! Please check your credentials.' });
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Login failed! Please check your credentials.',
            });
        }
    };

    const validateForm = ({ email, password }) => {
        const errors = {};
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
        }
        if (!password.trim()) {
            errors.password = 'Password is required';
        }
        return errors;
    };

    return (
        <>
            <section className='contactUs-Sec paddingY-100'>
                <div className="ah-container">
                    <div className="row justify-content-center bg-light-gray padding-70-100">
                        <div className="col-md-12 bg-color-box">
                            <div className="description text-center">
                                <div className='badge'><img src={RisingStars} alt="RisingStars" /><span>Login</span></div>
                                <br />
                                <h2>Login<span></span></h2>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            {!isSubmitted ? (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group'>
                                        <label htmlFor="email">Email:</label>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <p className="error-message text-danger ">{errors.email}</p>}
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="password">Password:</label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && <p className="error-message text-danger ">{errors.password}</p>}
                                    </div>
                                    <div className='form-group mt-4 mb-0'>
                                        <Button type="submit" className={`btn primary desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
                                            <Typography variant="button-text">Login</Typography>
                                        </Button>
                                    </div>
                                </form>
                            ) : (
                                <div className='text-center'>
                                    <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
                                </div>
                            )}
                            {errors.general && <p className="error-message">{errors.general}</p>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;